<template>
	<div class="dashboard-container">
		<el-card v-if="tableShow">
			<div class="flex-row-box">
				<div class="pl-1 pr-1">
					<span class="text-primary text-pad-right">日期</span>
					<el-date-picker v-model="condition.date" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd" size="mini" @change="changeTime" />
				</div>
				<div v-if="companyType" class="pl-1 pr-1">
					<span class="text-primary text-pad-right">企业名称</span>
					<el-select v-model="companyId" filterable size="mini" class="input searchInput" @change="changeCondition">
						<el-option v-for="(item,index) in company" :key="index" :value="item.id" :label="item.companyName" />
					</el-select>
				</div>
			</div>
		</el-card>
		<el-card style="margin-top: 20px;" v-if="tableShow">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col></el-col>
							<el-col :span="3">
								<vxe-button @click="defenseExport">导出Excel</vxe-button>
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="620" :data="table.list" :loading="loading" @cell-click="cellClick">
					<vxe-table-column title="时间" field="createTime" />
					<vxe-table-column title="预警数量（次）" field="warningNum" show-overflow="title" />
					<vxe-table-column title="血压异常（次）" field="bloodNum" show-overflow="title" />
					<vxe-table-column title="心率异常（次）" field="heartNum" show-overflow="title" />
					<vxe-table-column title="体温异常（次）" field="tempNum" show-overflow="title" />
					<vxe-table-column title="血氧饱和度异常（次）" field="oxygenNum" />
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
		<div v-if="!tableShow">
			<vxe-toolbar>
				<template v-slot:buttons>
					<el-row type="flex" justify="between">
						<el-col>
							<vxe-button @click="tableShow = true">返回</vxe-button>
						</el-col>
						<el-col :span="3">
							<vxe-button @click="defenseExport1">导出Excel</vxe-button>
							<vxe-button @click="shuaxin1">刷新</vxe-button>
						</el-col>
					</el-row>
				</template>
			</vxe-toolbar>
			<div>
				<el-row type="flex">
					<el-col :span="24" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{companyName ? companyName : '--'}}</div>
					</el-col>
				</el-row>
				<el-row type="flex">
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">报警时间</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{createTime ? createTime : '--'}}</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">报警次数</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{warningNum ? warningNum : '0'}}次</div>
					</el-col>
				</el-row>
				<el-row type="flex">
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">血压异常</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{bloodNum ? bloodNum : '0'}}次</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">心率异常</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{heartNum ? heartNum : '0'}}次</div>
					</el-col>
				</el-row>
				<el-row type="flex">
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">体温异常</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{tempNum ? tempNum : '0'}}次</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">血氧饱和度异常</div>
					</el-col>
					<el-col :span="6" style="border: 1px solid #e2e2e2;">
						<div class="cell-outer">{{oxygenNum ? oxygenNum : '0'}}次</div>
					</el-col>
				</el-row>
			</div>
			<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle1" :row-style="rowStyle"
			 align="center" class="vxe-table-element" height="560" :data="table1.list" :loading="loading">
				<vxe-table-column title="驾驶员姓名" field="userName" show-overflow="title" />
				<vxe-table-column title="部门" field="departName" show-overflow="title" />
				<vxe-table-column title="联系电话" field="phone" show-overflow="title" />
				<vxe-table-column title="报警时间" field="createTime" show-overflow="title" />
				<vxe-table-column title="报警类型" field="alarmType" show-overflow="title" />
				<vxe-table-column title="详情" field="monitorValue" show-overflow="title" />
			</vxe-table>
			<div style="height: 20px" />
			<el-pagination background :current-page="table1.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table1.pageSize"
			 layout="total, ->, sizes, prev, pager, next, jumper" :total="table1.total" @size-change="handleSizeChange1"
			 @current-change="handleCurrentChange1" />
		</div>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from '@/utils/handleByte'
	import {
		rowStyle,
		headerStyle,
		headerStyle1
	} from '@/utils/tableStyleJs'
	import {
		company
	} from '@/api'
	export default {
		name: 'Index',
		data() {
			return {
				loading: false,
				condition: {
					releaseStatus: [],
					search: '',
					date: [],
					start: '',
					end: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 20,
					sortName: '',
					sortBy: ''
				},
				table1: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				company: [],
				loginInfo: {},
				companyId:  this.$admin ? null : this.$companyId,
				warnType: [],
				companyType: false,
				alarmTypeList: [],
				alarmEventList: [],
				alarmEvent: '',
				alarmType: '',
				tableShow: true,
				bloodNum: '',
				companyName: '',
				createTime: '',
				heartNum: '',
				oxygenNum: '',
				tempNum: '',
				warningNum: '',
			}
		},
		created() {
			this.getCompany();
			if (this.$companyType == 0) {
				this.companyType = true;
			} else {
				this.companyType = false;
			}
		},
		methods: {
			rowStyle,
			headerStyle1,
			headerStyle,
			// 打开dialog
			openDialog(v) {
				alert(JSON.stringify(v))
			},
			changeCondition() {
				this.tableShow = true;
				this.table.currentPage = 1;
				this.table.list = [];
				this.getList()
			},
			shuaxin() {
				this.table.currentPage = 1;
				// this.companyId = '';
				this.condition.start = '';
				this.condition.end = '';
				this.condition.search = '';
				this.condition.releaseStatus = [];
				this.condition.date = [];
				this.tableShow = true;
				this.table.list = [];
				this.getList()
			},
			shuaxin1() {
				this.table1.currentPage = 1;
				this.table1.list = [];
				this.getHealthWarning();
			},
			defenseExport() {
				const loading = this.$loading({
					lock: true,
					text: '正在导出！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.$axiosReq('/archives/server/escort/web/healthWarning/export', null, {
					companyId: this.companyId,
					beginTime: this.condition.start ? this.condition.start + ' 00:00:00' : null,
					endTime: this.condition.end ? this.condition.end + ' 00:00:00' : null,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(() => {
					loading.close()
				})
			},
			defenseExport1() {
				const loading = this.$loading({
					lock: true,
					text: '正在导出！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.$axiosReq('/archives/server/escort/web/healthWarning/detail/export', null, {
					companyId: this.companyId,
					createTime: this.createTime ? this.createTime + ' 00:00:00' : null,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(() => {
					loading.close()
				})
			},
			changeTime(v) {
				this.table.currentPage = 1
				this.tableShow = true;
				this.table.list = [];
				if (v === null) {
					this.condition.start = ''
					this.condition.end = ''
				} else {
					this.condition.start = v[0]
					this.condition.end = v[1]
				}
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq('/archives/server/escort/web/healthWarning/page', null, {
					companyId: this.companyId,
					startTime: this.condition.start ? this.condition.start + ' 00:00:00' : null,
					endTime: this.condition.end ? this.condition.end + ' 00:00:00' : null,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(Number(res.data.totalElements))
				}).finally(e => {
					this.loading = false
				})
			},
			getHealthWarning() {
				this.loading = true
				this.$axiosReq('/archives/server/escort/web/healthWarning', null, {
					companyId: this.companyId,
					createTime: this.createTime ? this.createTime + ' 00:00:00' : null,
					currentPage: this.table1.currentPage,
					pageSize: this.table1.pageSize
				}, 'get').then(res => {
					this.table1.list = res.data.singleHealthWarnings.content;
					this.table1.total = Number(res.data.singleHealthWarnings.totalElements);
					this.bloodNum = res.data.bloodNum;
					this.companyName = res.data.companyName;
					this.createTime = res.data.createTime;
					this.heartNum = res.data.heartNum;
					this.oxygenNum = res.data.oxygenNum;
					this.tempNum = res.data.tempNum;
					this.warningNum = res.data.warningNum;
				}).finally(e => {
					this.loading = false
				})
			},
			getCompany() {
				this.$axiosReq(company, null, {
					currentPage: 1,
					pageSize: 1000,
				}, 'get').then(res => {
					if (res.data.content && res.data.content.length > 0) {
						this.company = res.data.content;
						this.companyId = res.data.content[0].id;
						this.getList();
					}
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			handleSizeChange1(pageSize) {
				this.table1.pageSize = pageSize
				this.table1.currentPage = 1;
				this.getHealthWarning()
			},
			handleCurrentChange1(currentPage) {
				this.table1.currentPage = currentPage
				this.getHealthWarning()
			},
			refreshDialog() {
				this.dialog.refresh = false
			},
			cellClick(v) {
				this.tableShow = false;
				this.createTime = v.row.createTime;
				this.table1.currentPage = 1;
				this.table1.list = [];
				this.getHealthWarning();
			},
		}
	}
</script>

<style scoped>
.cell-outer{
	width: 100%;
	height: 45px;
	line-height: 45px;
	text-align: center;
}
</style>
